h1, h2 {
  font-family: "Alegreya", serif;
  font-optical-sizing: auto;
  text-align: center;
}

.h1m {
  font-size: 80px;
}
.list-group {
  flex-direction: row !important;
  justify-content: center;
  list-style: none;
}
.list-group >li >a {
  color: #000000;
}
.bi {
  font-size: 3em;
  padding: 15px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .h1m {
    margin-top: 2em;
    margin-bottom: 1em;
    font-size: 50px;
  }
  img {
    width: 300px;
  }
  .list-group {
    margin-top: 3em;
  }
  .bi {
    font-size: 3em;
    padding: 15px;
  }  
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}